.exploreForm {
  width: 237px;
}

.explorePage {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  animation: explorePageAnimation 3s forwards;
}

@keyframes explorePageAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

ul {
  margin-right: 35px;
}

.videoResults {
  margin: auto;
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
  width: 90%;
  border-style: solid;
  border-radius: 10px;
  border-color: gray;
  border-width: 3px;
}

.videoList {
  margin-top: 55px;
}

.unsaved {
  visibility: visible;
}

.saved {
  visibility: hidden;
}

.explorePageAlt {
  display: none;
}

@media all and (min-width: 700px) {
  .exploreForm {
    width: 400px;
  }
}

@media all and (min-width: 1150px) {
  .explorePage {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
  }

  .exploreForm {
    width: 340px;
  }

  .videoResults {
    overflow: scroll;
    overflow-x: hidden;
    height: 500px;
    width: 100%;
  }
}