@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap');

.landingPageClass {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 1.5em;
}

.welcomeLanding {
    opacity: 0;
    animation: welcomeAnimationText 3s;
    animation-delay: 3s;
}

@keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}


.exploreLanding {
    opacity: 0;
    animation: exploreAnimationText 3s;
    animation-delay: 6s;
}

@keyframes exploreAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.playlistLanding {
    opacity: 0;
    animation: playlistAnimationText 3s;
    animation-delay: 9s;
}

@keyframes playlistAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}