.landingFormClass{
    margin-top: 20px;
}

.loginLandingIntro {
    margin-bottom: 20px;
    font-size: 19px;
}

@keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.needAccountButton {
    margin-top: 20px;
    font-size: 19px;
}