@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap');

.Hyph {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .Input,
  .Textarea {
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .Input::placeholder,
  .Textarea::placeholder {
    color: #ccc;
  }
  
  .Textarea {
    resize: vertical;
  }
  
  .Button {
    background-color: black;
    border: none;
    color: white;
    text-shadow: 0 0 20px rgb(255, 0, 149);
    padding: 16px 32px;
    text-decoration: none;
    font-size: 1.1em;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    font-weight: 900;
    letter-spacing: 1px;
    font-family: 'Quicksand', sans-serif;
  }

  .Required {
    color: #CA1551;
    font-family: Arial;
    font-weight: bold;
  }
  
  .Section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .Section--list {
    margin-left: 10px;
    margin-right: 10px;
  }
  
