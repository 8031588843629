/* .Autocomplete {
    width: 100%;
    border: 1px solid grey;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 4px 1px rgba(0,0,0,.18);
    height: 45px;
} */

Input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px;
    /* box-sizing: border-box; */
    outline: none;
}

.Autocomplete ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border-top: 1px solid grey;
} 

.Autocomplete ul::before {
    content: "";
}

.Autocomplete li {
    padding: 10px 5px;
    cursor: pointer;
}

.Autocomplete li:hover {
    text-decoration: underline;
}
