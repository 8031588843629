.allVideos {
  margin: auto;
  overflow: scroll;
  overflow-x: hidden;
  height: 500px;
  width: 80%;
  border-style: solid;
  border-radius: 10px;
  border-color: gray;
  border-width: 3px;
}

.inputField {
  width: 50%;
}
  
.videoTitle {
  font-size: medium;
}

  @media all and (min-width: 550px) {
    .allVideos {
      width: 60%;
    }
  }
  
  @media all and (min-width: 800px) {
    .allVideos {
      width: 40%;
    }
  }

  @media all and (min-width: 1200px) {
    .allVideos {
      width: 30%;
    }
  }