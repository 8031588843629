@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    background: black;
    -webkit-animation: headerAnimation 3s forwards;
            animation: headerAnimation 3s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

@-webkit-keyframes headerAnimation {
    from {min-height: 100vh;}
    to {min-height: 20vh;}
}

@keyframes headerAnimation {
    from {min-height: 100vh;}
    to {min-height: 20vh;}
}

.title {
    position: absolute;
    -webkit-transform: translate(45%, 100%);
            transform: translate(45%, 100%);
    font-size: 10vw;
    color: #fff;
    text-shadow: 0 0 20px rgb(255, 0, 149);
    font-family: 'Caveat', cursive;
}

.title:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    z-index: -1;
    color: rgb(255, 0, 149);
    -webkit-filter: blur(15px);
            filter: blur(15px);
    opacity: 1;
}

.title:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 0, 149);
    z-index: -2;
    opacity: .2;
    -webkit-filter: blur(40px);
            filter: blur(40px);
}

.itemDouble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 10px;
    margin-top: 130px;
    font-weight: 900;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
}

.itemExplore {
    opacity: 0;
    -webkit-animation: itemExploreAnimation 3s forwards;
            animation: itemExploreAnimation 3s forwards;
    -webkit-animation-delay: 6s;
            animation-delay: 6s;
}

.itemExplore:hover {
    text-decoration: underline;
}

@-webkit-keyframes itemExploreAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes itemExploreAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

.itemPlaylist {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    opacity: 0;
    -webkit-animation: itemPlaylistAnimation 3s forwards;
            animation: itemPlaylistAnimation 3s forwards;
    -webkit-animation-delay: 9s;
            animation-delay: 9s;
}

.itemPlaylist:hover {
    text-decoration: underline;
}

@-webkit-keyframes itemPlaylistAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes itemPlaylistAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

.itemLogin {
    opacity: 0;
    -webkit-animation: itemLoginAnimation 3s forwards;
            animation: itemLoginAnimation 3s forwards;
    -webkit-animation-delay: 12s;
            animation-delay: 12s;
}

.itemLogin:hover {
    text-decoration: underline;
}

@-webkit-keyframes itemLoginAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes itemLoginAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}



.numberOfItems {
    font-size: large;
    font-weight: 900;
    border-style: solid;
    border-width: 1px;
    background-color: rgb(223, 0, 0);
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

@media all and (min-width: 450px) {
    .title {
        -webkit-transform: translate(45%, 75%);
                transform: translate(45%, 75%);
    }
}

@media all and (min-width: 550px) {
    .title {
        -webkit-transform: translate(45%, 50%);
                transform: translate(45%, 50%);
    }
}

@media all and (min-width: 650px) {
    .title {
        -webkit-transform: translate(45%, 25%);
                transform: translate(45%, 25%);
    }
}

@media all and (min-width: 750px) {
    .title {
        -webkit-transform: translate(45%, 0%);
                transform: translate(45%, 0%);
    }

    .itemDouble {
        justify-content: space-around;
    }
}

@media all and (min-width: 1100px) {
    .title {
        font-size: 9vw;
        -webkit-transform: translate(55%, 0%);
                transform: translate(55%, 0%);
    }
}

@media all and (min-width: 1350px) {
    .title {
        font-size: 7vw;
        -webkit-transform: translate(85%, 0%);
                transform: translate(85%, 0%);
    }
}

@media all and (min-width: 1500px) {
    .title {
        font-size: 5vw;
        -webkit-transform: translate(140%, 0%);
                transform: translate(140%, 0%);
    }
}
.Hyph {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .Input,
  .Textarea {
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .Input::-webkit-input-placeholder, .Textarea::-webkit-input-placeholder {
    color: #ccc;
  }
  
  .Input::placeholder,
  .Textarea::placeholder {
    color: #ccc;
  }
  
  .Textarea {
    resize: vertical;
  }
  
  .Button {
    background-color: black;
    border: none;
    color: white;
    text-shadow: 0 0 20px rgb(255, 0, 149);
    padding: 16px 32px;
    text-decoration: none;
    font-size: 1.1em;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    font-weight: 900;
    letter-spacing: 1px;
    font-family: 'Quicksand', sans-serif;
  }

  .Required {
    color: #CA1551;
    font-family: Arial;
    font-weight: bold;
  }
  
  .Section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .Section--list {
    margin-left: 10px;
    margin-right: 10px;
  }
  

.errorMessage {
    color: red;
}

.successMessage {
    color: rgb(14, 190, 8);
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 19px;
}
.landingFormClass{
    margin-top: 20px;
}

.loginLandingIntro {
    margin-bottom: 20px;
    font-size: 19px;
}

@-webkit-keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.needAccountButton {
    margin-top: 20px;
    font-size: 19px;
}
/* .Autocomplete {
    width: 100%;
    border: 1px solid grey;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 4px 1px rgba(0,0,0,.18);
    height: 45px;
} */

Input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px;
    /* box-sizing: border-box; */
    outline: none;
}

.Autocomplete ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border-top: 1px solid grey;
} 

.Autocomplete ul::before {
    content: "";
}

.Autocomplete li {
    padding: 10px 5px;
    cursor: pointer;
}

.Autocomplete li:hover {
    text-decoration: underline;
}

.exploreForm {
  width: 237px;
}

.explorePage {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  -webkit-animation: explorePageAnimation 3s forwards;
          animation: explorePageAnimation 3s forwards;
}

@-webkit-keyframes explorePageAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes explorePageAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

ul {
  margin-right: 35px;
}

.videoResults {
  margin: auto;
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
  width: 90%;
  border-style: solid;
  border-radius: 10px;
  border-color: gray;
  border-width: 3px;
}

.videoList {
  margin-top: 55px;
}

.unsaved {
  visibility: visible;
}

.saved {
  visibility: hidden;
}

.explorePageAlt {
  display: none;
}

@media all and (min-width: 700px) {
  .exploreForm {
    width: 400px;
  }
}

@media all and (min-width: 1150px) {
  .explorePage {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
  }

  .exploreForm {
    width: 340px;
  }

  .videoResults {
    overflow: scroll;
    overflow-x: hidden;
    height: 500px;
    width: 100%;
  }
}
.landingPageClass {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 1.5em;
}

.welcomeLanding {
    opacity: 0;
    -webkit-animation: welcomeAnimationText 3s;
            animation: welcomeAnimationText 3s;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

@-webkit-keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}


.exploreLanding {
    opacity: 0;
    -webkit-animation: exploreAnimationText 3s;
            animation: exploreAnimationText 3s;
    -webkit-animation-delay: 6s;
            animation-delay: 6s;
}

@-webkit-keyframes exploreAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes exploreAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.playlistLanding {
    opacity: 0;
    -webkit-animation: playlistAnimationText 3s;
            animation: playlistAnimationText 3s;
    -webkit-animation-delay: 9s;
            animation-delay: 9s;
}

@-webkit-keyframes playlistAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes playlistAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}
.allVideos {
  margin: auto;
  overflow: scroll;
  overflow-x: hidden;
  height: 500px;
  width: 80%;
  border-style: solid;
  border-radius: 10px;
  border-color: gray;
  border-width: 3px;
}

.inputField {
  width: 50%;
}
  
.videoTitle {
  font-size: medium;
}

  @media all and (min-width: 550px) {
    .allVideos {
      width: 60%;
    }
  }
  
  @media all and (min-width: 800px) {
    .allVideos {
      width: 40%;
    }
  }

  @media all and (min-width: 1200px) {
    .allVideos {
      width: 30%;
    }
  }
.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.mainComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.videoList {
  list-style: none;
}

.buttonClass {
  background-color: black;
  border: none;
  color: #aaa;
  padding: 16px 32px;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  font-weight: 900;
  letter-spacing: 1px;
}
