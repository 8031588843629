@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap');

.App-header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    background: black;
    animation: headerAnimation 3s forwards;
    animation-delay: 2s;
}

@keyframes headerAnimation {
    from {min-height: 100vh;}
    to {min-height: 20vh;}
}

.title {
    position: absolute;
    transform: translate(45%, 100%);
    font-size: 10vw;
    color: #fff;
    text-shadow: 0 0 20px rgb(255, 0, 149);
    font-family: 'Caveat', cursive;
}

.title:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    z-index: -1;
    color: rgb(255, 0, 149);
    filter: blur(15px);
    opacity: 1;
}

.title:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 0, 149);
    z-index: -2;
    opacity: .2;
    filter: blur(40px);
}

.itemDouble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 10px;
    margin-top: 130px;
    font-weight: 900;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
}

.itemExplore {
    opacity: 0;
    animation: itemExploreAnimation 3s forwards;
    animation-delay: 6s;
}

.itemExplore:hover {
    text-decoration: underline;
}

@keyframes itemExploreAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

.itemPlaylist {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    opacity: 0;
    animation: itemPlaylistAnimation 3s forwards;
    animation-delay: 9s;
}

.itemPlaylist:hover {
    text-decoration: underline;
}

@keyframes itemPlaylistAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}

.itemLogin {
    opacity: 0;
    animation: itemLoginAnimation 3s forwards;
    animation-delay: 12s;
}

.itemLogin:hover {
    text-decoration: underline;
}

@keyframes itemLoginAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}



.numberOfItems {
    font-size: large;
    font-weight: 900;
    border-style: solid;
    border-width: 1px;
    background-color: rgb(223, 0, 0);
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

@media all and (min-width: 450px) {
    .title {
        transform: translate(45%, 75%);
    }
}

@media all and (min-width: 550px) {
    .title {
        transform: translate(45%, 50%);
    }
}

@media all and (min-width: 650px) {
    .title {
        transform: translate(45%, 25%);
    }
}

@media all and (min-width: 750px) {
    .title {
        transform: translate(45%, 0%);
    }

    .itemDouble {
        justify-content: space-around;
    }
}

@media all and (min-width: 1100px) {
    .title {
        font-size: 9vw;
        transform: translate(55%, 0%);
    }
}

@media all and (min-width: 1350px) {
    .title {
        font-size: 7vw;
        transform: translate(85%, 0%);
    }
}

@media all and (min-width: 1500px) {
    .title {
        font-size: 5vw;
        transform: translate(140%, 0%);
    }
}